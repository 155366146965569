<template>
  <div class="main-container"  dir="ltr">
        <div class="row" dir="rtl">
            <div class="col-xl-6 col-md-6">
                <div class="my-account-content2" dir="rtl" style="background: linear-gradient(0deg,#004d4d 0,#03acac );">
                    <div class="header-profile-login">
                        <div class="row justify-content-between">
                            <div class="col-8 col-sm-8 col-lg-8 col-xl-8">
                                <h6 class="text-center">الغاء الاشتراك</h6>
                            </div>
                            <div class="col-4 col-sm-4 col-lg-4 col-xl-4" style="padding-right: 0;">
                                <img style="width:130px;float:left;" class="logo" src="../../assets/images/logoSvg.svg" />
                            </div>
                        </div>
                        <form id="loginform" @submit.prevent="unsubscribe()">
                            <p class="login-username">
                                <label for="user_login" style="margin-top: 20px; margin-bottom: 20px; text-align: center;color: #fff;">هل تريد الغاء اشتراكك من منصة تعلم اللغة الإنجليزية</label>
                            </p>
                            <p class="login-submit">
                                <button class="button button-primary" style="width: 100%; background: linear-gradient(0deg,#077b7b 0,#03acac ); color: rgb(255, 255, 255);">
                                    <span v-if="loading" class="px-1 arabickufi">جاري الغاء اشتراكك</span> &nbsp;
                                    <div v-if="loading" class="spinner-border spinner-border-sm"></div>
                                    <span v-else class="arabickufi">الغاء الاشتراك</span>
                                </button>
                                <!-- <button type="submit" style="width: 100%;background: #078CC5;" name="wp-submit" id="wp-submit" class="button button-primary" value="إشتـــراك"></button> -->
                            </p>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { HTTPDSP } from '@/Api/http-dsp';
import { useToast } from "vue-toastification";
export default {
   name: 'AppUnsubscribe',
   data() {
     return {
       loading: false,
     };
   },
   methods: {
      async unsubscribe () {
        const toast = useToast();
          let button = document.querySelector(".button");
          button.disabled = true;
          this.loading = true;
        await HTTPDSP.post("DSPUnsubscribe.php?msisdn="+this.$cookie.getCookie("msisdn")).then((response) => {
          if (response.data.status == 0) {
            toast.error("انت لست مشترك في منصة تعلم اللغة الإنجليزية");
            this.$cookie.keys().forEach(cookie => this.$cookie.removeCookie(cookie))
            this.$router.push('/')
          } else {
            toast.success("تم الغاء اشتراكك من منصة تعلم اللغة الإنجليزية");
            this.$cookie.keys().forEach(cookie => this.$cookie.removeCookie(cookie))
            this.$router.push('/')
          }
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            this.loading = false;
        });
      },
   }
}
</script>

<style>

</style>